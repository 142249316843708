import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '../lib/styled';

const Button = styled.button
    .attrs({ type: 'submit ' })`
        background-color: ${vars.primary};
        border: 0;
        color: ${vars.light};
        line-height: 3rem;
        font-size: 0.833em;
        padding: 0 2rem;
        transition: background-color 500ms;

        &:hover {
            background-color: ${vars.primaryHover};
        }
    `;

const Submit = ({ loading, text }) => (
    <Button>
        {loading ? 'Thinking ... ' : text}
    </Button>
);

Submit.propTypes = {
    loading: PropTypes.bool,
    text: PropTypes.string
};

export default Submit;