import React, { Component } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import posed from "react-pose";
import withMedia from "./withMedia";

export const Paragraph = styled(
  posed.p({
    enter: { opacity: 1, transition: { duration: 500 } },
    exit: { opacity: 0 },
  })
)`
  background-color: ${(props) => (props.success ? "#00982A" : "#980B0D")};
  box-shadow: 2px 1px -11px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
  padding: 1rem 2rem;
  font-size: 0.833em;
`;

export default class Alert extends Component {
  timer = null;
  state = { posed: null };

  componentDidMount = () => {
    this.setState({
      posed: "enter",
    });

    if (this.props.timeout)
      this.timer = setTimeout(
        () =>
          this.setState({
            posed: "exit",
          }),
        2000
      );
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  render = () => (
    <Paragraph initialPose="exit" {...this.props} pose={this.state.posed}>
      {this.props.children}
    </Paragraph>
  );
}

Alert.propTypes = {
  timeout: PropTypes.bool,
};

Alert.defaultProps = {
  timeout: true,
};
