import styled, { css } from 'styled-components';
import withMedia from './withMedia';

export default styled.div`
    background-color: ${props => props.muted ? '#E7E8ED' : '#1F1F1F'};
    color: ${props => props.muted ? 'inherit' : '#FFF'};
    padding: 2rem 1rem;

    ${withMedia('phone', css`
        padding: 1rem;
    `)}
`;